/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  MintGatewayStateV2,
  MintGatewayStateV2Interface,
} from "../MintGatewayStateV2";

const _abi = [
  {
    constant: true,
    inputs: [],
    name: "selectorHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052348015600f57600080fd5b5060848061001e6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80632976297614602d575b600080fd5b60336049565b6040518082815260200191505060405180910390f35b6001548156fea265627a7a72315820b28bed982dc53c62ec8b7307470ba0e9479259db3970fb0ee119e0f4de35dc2164736f6c63430005110032";

export class MintGatewayStateV2__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MintGatewayStateV2> {
    return super.deploy(overrides || {}) as Promise<MintGatewayStateV2>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): MintGatewayStateV2 {
    return super.attach(address) as MintGatewayStateV2;
  }
  connect(signer: Signer): MintGatewayStateV2__factory {
    return super.connect(signer) as MintGatewayStateV2__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MintGatewayStateV2Interface {
    return new utils.Interface(_abi) as MintGatewayStateV2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MintGatewayStateV2 {
    return new Contract(address, _abi, signerOrProvider) as MintGatewayStateV2;
  }
}
