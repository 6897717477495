/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { LinkedList, LinkedListInterface } from "../LinkedList";

const _abi = [
  {
    constant: true,
    inputs: [],
    name: "NULL",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x60ba610025600b82828239805160001a60731461001857fe5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060335760003560e01c8063f26be3fc146038575b600080fd5b603e6080565b604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390f35b60008156fea265627a7a7231582099c84bd2773891ebffc9215aa81f23da4e0059abac0ba2fa04a25211c479940564736f6c63430005110032";

export class LinkedList__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<LinkedList> {
    return super.deploy(overrides || {}) as Promise<LinkedList>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): LinkedList {
    return super.attach(address) as LinkedList;
  }
  connect(signer: Signer): LinkedList__factory {
    return super.connect(signer) as LinkedList__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): LinkedListInterface {
    return new utils.Interface(_abi) as LinkedListInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LinkedList {
    return new Contract(address, _abi, signerOrProvider) as LinkedList;
  }
}
